import React, {useState} from 'react';


function Header() {

 
  return (
    <header>   
      <img src='https://gallery.yopriceville.com/var/resizes/Free-Clipart-Pictures/Cartoons-PNG/Toucan_Cartoon_PNG_Clip_Art_Image.png?m=1532517238' id='toucan' ></img>
          <h1>~  AC - 2 - Market  ~</h1>


    </header>
  );
}

export default Header;
